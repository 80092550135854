import { useNavigate, useParams } from "react-router-dom";
import { MultiSlider } from "../MultiSlider/MultiSlider";
import { useDispatch, useSelector } from "react-redux";
import { resetFiltersAndSort, updateFilters } from "../../redux/cvs/cvsSlice";
import {
  selectAccessToken,
  selectLoadedFilters,
  selectSearchFilters,
} from "../../redux/selectors";
import { getSearchById, getSynonyms } from "../../redux/cvs/cvsOperations";
import { useEffect, useState } from "react";
import css from "./Filters.module.css";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { areEqual } from "../../utils";
import { setAuthToken } from "../../redux/auth/authApi";
import { setFiltersError } from "../../redux/global/glolbalSlice";
import { Categories } from "../Categories/Categories";
import svgSrc from "../../images/sprite.svg";

const schema = yup
  .object({
    keyword: yup
      .string()
      .required("Position name is required")
      .min(2, "Position name should be 2 letters at least")
      .matches(/^[^,]+(?:,[^,]+)*$/, {
        excludeEmptyString: true,
        message: "Input position names, separated by commas",
      })
      .max(500, "Position names shouldn't be longer than 500 letters"),
    city: yup
      .string()
      .matches(/.{2,}/, {
        excludeEmptyString: true,
        message: "City should be 2 letters at least",
      })
      .matches(/^[А-Яа-яЇїІіЄєҐґ][А-Яа-яЇїІіЄєҐґ '-]*$/, {
        excludeEmptyString: true,
        message:
          "City should contain only Ukrainian Cyrillic letters, spaces, or hyphens and no commas",
      })
      .max(60, "City shouldn't be longer than 60 letters"),
    keywords: yup
      .string()
      .matches(/^[^,]+(?:,[^,]+)*$/, {
        excludeEmptyString: true,
        message: "Input keywords, separated by commas",
      })
      .max(500, "Your keywords shouldn't be longer than 500 letters"),
  })
  .required();

export const Filters = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { searchId } = useParams();
  const searchFilters = useSelector(selectSearchFilters);
  const loadedFilters = useSelector(selectLoadedFilters);
  const accessToken = useSelector(selectAccessToken);
  const [title, setTitle] = useState("");
  const isUpdate = areEqual(searchFilters, loadedFilters);

  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
    setValue,
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (searchId) {
      dispatch(resetFiltersAndSort());
      setAuthToken(accessToken);
      dispatch(getSearchById(searchId));
    }
  }, [dispatch, searchId, accessToken]);

  useEffect(() => {
    if (!isUpdate) setTitle("Create your search");
    else setTitle("Update your search");
  }, [isUpdate]);

  useEffect(() => {
    setValue("keyword", searchFilters.keyword);
    setValue("city", searchFilters.city);
    setValue("keywords", searchFilters.keywords);
  }, [searchFilters, setValue]);

  useEffect(() => {
    if (
      errors.city ||
      errors.keyword ||
      errors.keywords ||
      !searchFilters.keyword
    ) {
      dispatch(setFiltersError(true));
    } else {
      dispatch(setFiltersError(false));
    }
  }, [
    dispatch,
    errors.city,
    errors.keyword,
    errors.keywords,
    searchFilters.keyword,
  ]);

  const submitHandler = () => {
    navigate("/create/sort");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(updateFilters({ [name]: value }));
    setValue(name, value);
    trigger(name);
  };

  const handleCheckedChange = (e) => {
    const { name, checked } = e.target;
    dispatch(updateFilters({ [name]: checked }));
  };

  const handleExperienceChange = (e) => {
    const { id, checked } = e.target;
    const updatedExperience = [...searchFilters.seniority];
    updatedExperience[id] = checked;
    dispatch(updateFilters({ seniority: updatedExperience }));
  };

  const handleMultipleChange = (e) => {
    let selectedValues = Array.from(e.target.selectedOptions).map(
      (option) => option.value
    );
    if (selectedValues.includes("All"))
      selectedValues = Array.from(e.target.options)
        .map((option) => option.value)
        .splice(1);
    dispatch(updateFilters({ sites: selectedValues }));
  };

  const clickHandler = (e) => {
    dispatch(getSynonyms(searchFilters.keyword));
  };

  return (
    <div className={css.filtersContainer}>
      <h1 className={css.title}>{title}</h1>
      <h2 className={css.subTitle}>Criteria</h2>
      <form onSubmit={handleSubmit(submitHandler)}>
        <div className={css.itemsContainer}>
          <label htmlFor="keyword" className={css.label}>
            Position names:
            <div className={css.flexContainer}>
              <textarea
                {...register("keyword")}
                type="text"
                id="keyword"
                name="keyword"
                placeholder="e.g. Project Manager, PM"
                value={searchFilters.keyword}
                onChange={handleChange}
                className={`${css.input} ${css.textarea} ${
                  errors.keyword && css.error
                }`}
              />
              <button
                type="button"
                onClick={clickHandler}
                className={css.magicButton}
                disabled={
                  searchFilters.keyword.length > 400 ||
                  searchFilters.keyword.length < 2
                }
              ></button>
            </div>
            <p className={css.errorMessage}>{errors.keyword?.message}</p>
          </label>
        </div>
        <div className={css.itemsContainer}>
          <label htmlFor="city" className={css.label}>
            City:
            <input
              {...register("city")}
              type="text"
              id="city"
              name="city"
              placeholder="e.g. New York"
              value={searchFilters.city}
              onChange={handleChange}
              className={`${css.input} ${errors.city && css.error}`}
            />
            <p className={css.errorMessage}>{errors.city?.message}</p>
          </label>
        </div>
        <div className={css.itemsContainer}>
          <label htmlFor="employment-type" className={css.label}>
            Employment Type:
            <select
              id="employment-type"
              name="fullEmployment"
              value={searchFilters.fullEmployment}
              onChange={handleChange}
              className={css.input}
            >
              <option value="">Any</option>
              <option value={true}>Full-Time</option>
              <option value={false}>Part-Time</option>
            </select>
          </label>
        </div>
        <div className={css.slidersContainer}>
          <p className={css.labelSlider}>Candidate's Age</p>
          <div className={css.slider}>
            <MultiSlider
              id="age"
              name="age"
              min={18}
              max={65}
              minValue={searchFilters.minAge}
              maxValue={searchFilters.maxAge}
            />
          </div>
        </div>
        <div className={css.itemsContainer}>
          <label className={css.labelCheckbox}>
            <input
              type="checkbox"
              id="photo"
              name="photo"
              checked={searchFilters.photo}
              onChange={handleCheckedChange}
              className={css.checkbox}
            />
            <span>Resume with photo</span>
          </label>
        </div>
        <div className={css.itemsContainer}>
          <label className={css.labelCheckbox}>
            <input
              type="checkbox"
              id="noSalary"
              name="noSalary"
              checked={searchFilters.noSalary}
              onChange={handleCheckedChange}
              className={css.checkbox}
            />
            <span>Include without salary</span>
          </label>
        </div>
        <div className={css.itemsContainer}>
          <label className={css.labelCheckbox}>
            <input
              type="checkbox"
              id="showExpired"
              name="showExpired"
              checked={searchFilters.showExpired}
              onChange={handleCheckedChange}
              className={css.checkbox}
            />
            <span>Re-send after 30 days</span>
          </label>
        </div>
        <div className={css.slidersContainer}>
          <p className={css.labelSlider}>Expected Salary (in thousands)</p>
          <div className={css.slider}>
            <MultiSlider
              name="salary"
              id="salary"
              min={0}
              max={200}
              minValue={searchFilters.minSalary}
              maxValue={searchFilters.maxSalary}
            />
          </div>
        </div>
        <div className={css.itemsContainer}>
          <label htmlFor="education" className={css.labelCheckbox}>
            <input
              type="checkbox"
              id="education"
              name="education"
              checked={searchFilters.education}
              onChange={handleCheckedChange}
              className={css.checkbox}
            />
            <span>Higher Education Required</span>
          </label>
        </div>
        <div className={css.itemsContainer}>
          <p className={css.experienceTitle}>Candidate's Experience</p>
          {[0, 1, 2, 3, 4, 5].map((id) => (
            <div key={id} className={css.experienceItemContainer}>
              <label className={css.labelCheckbox}>
                <input
                  type="checkbox"
                  id={id}
                  name="experience"
                  checked={searchFilters.seniority[id]}
                  onChange={handleExperienceChange}
                  className={css.checkbox}
                />

                {id === 0
                  ? "No experience"
                  : id === 1
                  ? "Less than 1 year"
                  : id === 2
                  ? "1-2 years"
                  : id === 3
                  ? "2-5 years"
                  : id === 4
                  ? "5 years or more"
                  : "Worked last year "}
              </label>
            </div>
          ))}
        </div>
        <div className={css.itemsContainer}>
          <p className={css.categoryTitle}>Category</p>
          <p className={css.categoryDescription}>
            If no category or all categories are selected, the search is
            performed without using categories.
            <br /> Select a category to narrow your search
          </p>
          <Categories />
        </div>
        <div className={css.itemsContainer}>
          <label htmlFor="english" className={css.label}>
            English Required:
            <select
              id="english"
              name="english"
              value={searchFilters.english}
              onChange={handleChange}
              className={css.input}
            >
              <option value="">-- Select English level --</option>
              <option value={0}>Basic</option>
              <option value={1}>Intermediate</option>
              <option value={2}>Upper Intermediate</option>
              <option value={3}>Advanced</option>
              <option value={4}>Fluent</option>
            </select>
          </label>
        </div>
        <div className={css.itemsContainer}>
          <label htmlFor="gender" className={css.label}>
            Gender:
            <select
              id="gender"
              name="gender"
              value={searchFilters.gender}
              onChange={handleChange}
              className={css.input}
            >
              <option value="">Any</option>
              <option value={true}>Male</option>
              <option value={false}>Female</option>
            </select>
          </label>
        </div>
        <div className={css.itemsContainer}>
          <label htmlFor="work-type" className={css.label}>
            Work Type:
            <select
              id="work-type"
              name="inOffice"
              value={searchFilters.inOffice}
              onChange={handleChange}
              className={css.input}
            >
              <option value="">Any</option>
              <option value={true}>Office</option>
              <option value={false}>Remote</option>
            </select>
          </label>
        </div>
        <div className={css.itemsContainer}>
          <label htmlFor="keywords" className={css.label}>
            Keywords:
            <textarea
              {...register("keywords")}
              type="text"
              id="keywords"
              name="keywords"
              value={searchFilters.keywords}
              placeholder="Creative, Designer, UX, UI, Art, Digital"
              onChange={handleChange}
              className={`${css.input} ${css.textarea} ${
                errors.keywords && css.error
              }`}
            />
            <p className={css.errorMessage}>{errors.keywords?.message}</p>
          </label>
        </div>
        <div className={css.itemsContainer}>
          <label htmlFor="sites" className={css.label}>
            Sites to use:
            <select
              id="sites"
              name="sites"
              value={searchFilters.sites}
              onChange={handleMultipleChange}
              className={`${css.input} ${css.multiple}`}
              multiple
              size={3}
            >
              <option value="All">-- All --</option>
              <option value="work.ua">Work.ua</option>
              <option value="robota.ua">Robota.ua</option>
            </select>
          </label>
        </div>
        <button type="submit" className={css.nextButtton}>
          Next
        </button>
      </form>
    </div>
  );
};
